* {
  box-sizing: border-box;
  margin: 0;
}

body {
  color: #fff;
  background: #000;
  height: 100vh;
}

.js {
  display: none;
}

.video-wrapper {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

video {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
/*# sourceMappingURL=index.5e396913.css.map */
