* {
    box-sizing: border-box;
    margin: 0;
}
body {
    background: #000;
    color: #fff;
    height: 100vh;
}
.js {
    display: none;
}
.video-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
